<template>
    <div class="claim_company">
        <el-table :data="dataList" header-cell-class-name="bg_color" border show-summary :summary-method="getSummaries">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column :prop="item.prop" :label="item.label" v-for="(item, i) in labelProp" :key="i" min-width="120px">
                <!-- <template slot-scope="scope">
                    {{ scope.row }}
                    </template>-->
            </jy-table-column>
        </el-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: []
        }
    },
    props: {
        labelProp: {
            type: Array,
            default () {
                return []
            }
        },
        formInline: {
            type: Object,
            default () {
                return {}
            }
        },
        url: {
            type: String,
            default: ''
        }
    },
    methods: {
        getList() {
            console.log(this.url)
            let url = this.url
            let option = {
                ...this.formInline,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            }
            this.$http.post(url, option).then(({ detail }) => {
                this.dataList = detail.list
                this.total = detail.total
            })
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        getSummaries(param){
            const { columns, data } = param
			const sums = [];
            if(data&&data.length){
                columns.forEach((column, index) => {
                    let type = Object.prototype.toString.call(data[0][column.property])
                    console.log(type.slice(8,-1))
                    if(index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if(type && type.slice(8,-1)=='Number'){
                        let total = 0
                        data.forEach(item=>{
                            total = total+item[column.property]
                        })
                        sums[index] = total.toFixed(2)
                    }else{
                        sums[index] =''
                    }
                })
            }
            return sums
        },
    }
}

</script>
<style lang="scss">
.claim_company {
  .el-table{
    overflow-x: auto;
  }
  .el-table__body-wrapper,
  .el-table__header-wrapper,
  .el-table__footer-wrapper{
    overflow:visible;
  }
  .el-table::after{
    position: relative !important;
  }
}

</style>
