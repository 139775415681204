<template>
    <div class="reportCenter">
        <jy-query ref="checkForm" :model="formInline" label-width="72px">
            <jy-query-item label="机构:" prop="orgName">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="formInline.orgName"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="车牌号:" prop="vehicleNo">
                <el-input v-model="formInline.vehicleNo" placeholder="请输入" :disabled="carIs"></el-input>
            </jy-query-item>
            <jy-query-item label="司机:" prop="driverName">
                <el-input v-model="formInline.driverName" placeholder="请输入" :disabled="driverIs"></el-input>
            </jy-query-item>
            <jy-query-item label="查询日期:" prop="wztime" :span="1.5">
                <el-date-picker v-model="formInline.wztime" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :unlink-panels="true" :picker-options="pickerOptions"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('checkForm')">重置</el-button>
                <el-row>
                    <!-- <el-button type="primary" @click="add">新增</el-button>
                            <el-button type="primary" @click="batchdelete">批量删除</el-button>-->
                    <!-- <el-button type="primary" @click="onexport">导出</el-button> -->
                    <!-- <el-button type="primary" @click="batchimport">导入</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <div class="container_box">
            <div class="left_box">
                <div class="text_box">功能项列表</div>
                <el-tree :data="data" :props="defaultProps" accordion @node-click="handleNodeClick" class="tree_box" node-key="id" :default-expanded-keys="defaultzk" :current-node-key="2" :highlight-current="true"></el-tree>
            </div>
            <div class="right_box">
                <list ref="listData" :labelProp="labelProp" :formInline="formInline" :url="url"></list>
            </div>
        </div>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/system/org/queryOrgTree" title="选择机构" selectType="3" typeName="orgType"></institutions-tree>
    </div>
</template>
<script>
import list from '@/components/pages/admin/statisticalAnalysis/report/reportCenter/list'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
export default {
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                },
            },
            formInline: {
                orgName: '',
                orgId: '',
                vehicleNo: '',
                driverName: '',
                wztime: '',
                beginTime: '',
                endTime: '',
            },
            carIs: false,
            driverIs: false,
            type: 1,

            defaultzk: [1],
            lists: [
                {
                    a: '快充王',
                    b: 1,
                    c: '2019-10-10',
                    d: 'text',
                    e: 'text',
                    f: 'text',
                    g: 'text',
                    h: 'text',
                    i: 'text',
                    j: 'text',
                    k: 'text',
                    l: 'text',
                    m: 'text',
                    n: 'text',
                    o: 'text',
                    p: 'text',
                    q: 'text',
                    r: 'text',
                    s: 'text',
                    t: 'text',
                    u: 'text',
                    v: 'text',
                    w: 'text',
                    x: 'text',
                    y: 'text',
                    z: 'text',
                    beizhu: 'text',
                    time: 'text',
                },
            ],
            url: '/statoperatingdaily/queryVehiclePageList',
            // 批量删除
            batchdeleteList: [],
            // 分页
            //   pageIndex: 1,
            //   total: 100,
            //   pageSize: 10,

            // 树形结构 type: 1(车辆) 2(司机) 3(线路)
            data: [
                {
                    label: '运营生产数据统计',
                    id: 1,
                    children: [
                        {
                            label: '车辆汇总',
                            id: 2,
                            value: [
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'customNo',
                                    label: '车辆自编号',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShfit',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'completeShift',
                                    label: '完成班次',
                                },
                                {
                                    prop: 'incompleteShift',
                                    label: '未完成班次',
                                },
                                {
                                    prop: 'rentShift',
                                    label: '定点专线班次',
                                },
                                {
                                    prop: 'operatingMil',
                                    label: '营运里程（km）',
                                },
                                {
                                    prop: 'rentShiftMil',
                                    label: '定点专线里程（km）',
                                },
                                {
                                    prop: 'nonOperatingMil',
                                    label: '非营运里程（km）',
                                },
                                {
                                    prop: 'totalMil',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'runTotalMil',
                                    label: '运行总里程（km）',
                                },
                                {
                                    prop: 'planShift',
                                    label: '应开班次',
                                },
                                {
                                    prop: 'cutShift',
                                    label: '砍班班次',
                                },
                                {
                                    prop: 'cutShiftRate',
                                    label: '砍班率（%）',
                                },
                                {
                                    prop: 'outShift',
                                    label: '脱班班次',
                                },
                                {
                                    prop: 'outShiftRate',
                                    label: '脱班率（%）',
                                },
                                {
                                    prop: 'shiftExecutionRate',
                                    label: '班次执行率（%）',
                                },
                                {
                                    prop: 'depOnTimeNum',
                                    label: '准点发班数',
                                },
                                {
                                    prop: 'depOnTimeRate',
                                    label: '发车准点率（%）',
                                },
                            ],
                            url: '/statoperatingdaily/queryVehiclePageList',
                            type: 1,
                        },
                        {
                            label: '司机汇总',
                            id: 3,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'driverName',
                                    label: '司机',
                                },
                                {
                                    prop: 'jobNum',
                                    label: '员工工号',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShfit',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'completeShift',
                                    label: '完成班次',
                                },
                                {
                                    prop: 'incompleteShift',
                                    label: '未完成班次',
                                },
                                {
                                    prop: 'rentShift',
                                    label: '定点专线班次',
                                },
                                {
                                    prop: 'operatingMil',
                                    label: '营运里程（km）',
                                },
                                {
                                    prop: 'rentShiftMil',
                                    label: '定点专线里程（km）',
                                },
                                {
                                    prop: 'nonOperatingMil',
                                    label: '非营运里程（km）',
                                },
                                {
                                    prop: 'totalMil',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'cutShift',
                                    label: '砍班班次',
                                },
                                {
                                    prop: 'cutShiftRate',
                                    label: '砍班率（%）',
                                },
                                {
                                    prop: 'outShift',
                                    label: '脱班班次',
                                },
                                {
                                    prop: 'outShiftRate',
                                    label: '脱班率（%）',
                                },
                                {
                                    prop: 'depOnTimeNum',
                                    label: '准点发班数',
                                },
                                {
                                    prop: 'depOnTimeRate',
                                    label: '发车准点率（%）',
                                },
                            ],
                            url: '/statoperatingdaily/queryPageListByDriver',
                            type: 2,
                        },
                        {
                            label: '线路汇总',
                            id: 4,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShfit',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'completeShift',
                                    label: '完成班次',
                                },
                                {
                                    prop: 'incompleteShift',
                                    label: '未完成班次',
                                },
                                {
                                    prop: 'rentShift',
                                    label: '定点专线班次',
                                },
                                {
                                    prop: 'operatingMil',
                                    label: '营运里程（km）',
                                },
                                {
                                    prop: 'rentShiftMil',
                                    label: '定点专线里程（km）',
                                },
                                {
                                    prop: 'totalMil',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '运行总里程（km）',
                                },
                                {
                                    prop: 'cutShift',
                                    label: '砍班班次',
                                },
                                {
                                    prop: 'cutShiftRate',
                                    label: '砍班率（%）',
                                },
                                {
                                    prop: 'outShift',
                                    label: '脱班班次',
                                },
                                {
                                    prop: 'outShiftRate',
                                    label: '脱班率（%）',
                                },
                                {
                                    prop: 'depOnTimeNum',
                                    label: '准点发班数',
                                },
                                {
                                    prop: 'depOnTimeRate',
                                    label: '发车准点率（%）',
                                },
                            ],
                            url: '/statoperatingdaily/queryPageListByRoute',
                            type: 3,
                        },
                        {
                            label: '车辆明细',
                            id: 5,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'time',
                                    label: '营运日期',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车辆自编号',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShfit',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'completeShift',
                                    label: '完成班次',
                                },
                                {
                                    prop: 'incompleteShift',
                                    label: '未完成班次',
                                },
                                {
                                    prop: 'rentShift',
                                    label: '定点专线班次',
                                },
                                {
                                    prop: 'operatingMil',
                                    label: '营运里程（km）',
                                },
                                {
                                    prop: 'rentShiftMil',
                                    label: '定点专线里程（km）',
                                },
                                {
                                    prop: 'nonOperatingMil',
                                    label: '非营运里程（km）',
                                },
                                {
                                    prop: 'totalMil',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '运行总里程（km）',
                                },
                                {
                                    prop: 'cutShift',
                                    label: '砍班班次',
                                },
                                {
                                    prop: 'cutShiftRate',
                                    label: '砍班率（%）',
                                },
                                {
                                    prop: 'outShift',
                                    label: '脱班班次',
                                },
                                {
                                    prop: 'outShiftRate',
                                    label: '脱班率（%）',
                                },
                                {
                                    prop: 'depOnTimeNum',
                                    label: '准点发班数',
                                },
                                {
                                    prop: 'depOnTimeRate',
                                    label: '发车准点率（%）',
                                },
                            ],
                            url:
                                '/statoperatingdaily/queryOperateDetailByVehicle',
                            type: 1,
                        },
                        {
                            label: '司机明细',
                            id: 6,
                            value: [
                                {
                                    prop: 'time',
                                    label: '营运日期',
                                },
                                {
                                    prop: 'jobNum',
                                    label: '员工工号',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车辆自编号',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShfit',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'completeShift',
                                    label: '完成班次',
                                },
                                {
                                    prop: 'incompleteShift',
                                    label: '未完成班次',
                                },
                                {
                                    prop: 'rentShift',
                                    label: '定点专线班次',
                                },
                                {
                                    prop: 'operatingMil',
                                    label: '营运里程（km）',
                                },
                                {
                                    prop: 'rentShiftMil',
                                    label: '定点专线里程（km）',
                                },
                                {
                                    prop: 'totalMil',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '运行总里程（km）',
                                },
                                {
                                    prop: 'cutShift',
                                    label: '砍班班次',
                                },
                                {
                                    prop: 'cutShiftRate',
                                    label: '砍班率（%）',
                                },
                                {
                                    prop: 'outShift',
                                    label: '脱班班次',
                                },
                                {
                                    prop: 'outShiftRate',
                                    label: '脱班率（%）',
                                },
                                {
                                    prop: 'depOnTimeNum',
                                    label: '准点发班数',
                                },
                                {
                                    prop: 'depOnTimeRate',
                                    label: '发车准点率（%）',
                                },
                            ],
                            url:
                                '/statoperatingdaily/queryOperateDetailByDriver',
                            type: 2,
                        },
                        {
                            label: '线路明细',
                            id: 7,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'time',
                                    label: '营运日期',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShfit',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'completeShift',
                                    label: '完成班次',
                                },
                                {
                                    prop: 'incompleteShift',
                                    label: '未完成班次',
                                },
                                {
                                    prop: 'rentShift',
                                    label: '定点专线班次',
                                },
                                {
                                    prop: 'operatingMil',
                                    label: '营运里程（km）',
                                },
                                {
                                    prop: 'rentShiftMil',
                                    label: '定点专线里程（km）',
                                },
                                {
                                    prop: 'totalMil',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '运行总里程（km）',
                                },
                                {
                                    prop: 'cutShift',
                                    label: '砍班班次',
                                },
                                {
                                    prop: 'cutShiftRate',
                                    label: '砍班率（%）',
                                },
                                {
                                    prop: 'outShift',
                                    label: '脱班班次',
                                },
                                {
                                    prop: 'outShiftRate',
                                    label: '脱班率（%）',
                                },
                                {
                                    prop: 'depOnTimeNum',
                                    label: '准点发班数',
                                },
                                {
                                    prop: 'depOnTimeRate',
                                    label: '发车准点率（%）',
                                },
                            ],
                            url:
                                '/statoperatingdaily/queryOperateDetailByRoute',
                            type: 3,
                        },
                    ],
                },
                {
                    label: '非营运生产数据统计',
                    id: 8,
                    children: [
                        {
                            label: '车辆汇总',
                            id: 9,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车辆自编号',
                                },
                                {
                                    prop: 'firstLeaveMil',
                                    label: '首班出场运行里程（km）',
                                },
                                {
                                    prop: 'midBackMil',
                                    label: '中间回场运行里程（km)',
                                },
                                {
                                    prop: 'midLeaveMil',
                                    label: '中间出场运行里程（km）',
                                },
                                {
                                    prop: 'lastBackMil',
                                    label: '末班回场运行里程（km）',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '维修出场运行里程（km）',
                                },
                                {
                                    prop: 'otherLeaveMil',
                                    label: '其他出场运行里程（km）',
                                },
                                {
                                    prop: 'gasFillMil',
                                    label: '加油申请运行里程（km）',
                                },
                                {
                                    prop: 'maintainMil',
                                    label: '维修申请运行里程（km）',
                                },
                                {
                                    prop: 'otherMil',
                                    label: '其他申请运行里程（km）',
                                },
                            ],
                            url:
                                '/statnonoperatedetail/queryNonOperateDataByVehicle',
                            type: 1,
                        },
                        {
                            label: '司机汇总',
                            id: 10,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'driverName',
                                    label: '司机',
                                },
                                {
                                    prop: 'jobNum',
                                    label: '员工工号',
                                },
                                {
                                    prop: 'firstLeaveMil',
                                    label: '首班出场运行里程（km）',
                                },
                                {
                                    prop: 'midBackMil',
                                    label: '中间回场运行里程（km)',
                                },
                                {
                                    prop: 'midLeaveMil',
                                    label: '中间出场运行里程（km）',
                                },
                                {
                                    prop: 'lastBackMil',
                                    label: '末班回场运行里程（km）',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '维修出场运行里程（km）',
                                },
                                {
                                    prop: 'otherLeaveMil',
                                    label: '其他出场运行里程（km）',
                                },
                                {
                                    prop: 'gasFillMil',
                                    label: '加油申请运行里程（km）',
                                },
                                {
                                    prop: 'maintainMil',
                                    label: '维修申请运行里程（km）',
                                },
                                {
                                    prop: 'otherMil',
                                    label: '其他申请运行里程（km）',
                                },
                            ],
                            url:
                                '/statnonoperatedetail/queryNonOperateDataByDriver',
                            type: 2,
                        },
                        {
                            label: '线路汇总',
                            id: 11,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'firstLeaveMil',
                                    label: '首班出场运行里程（km）',
                                },
                                {
                                    prop: 'midBackMil',
                                    label: '中间回场运行里程（km)',
                                },
                                {
                                    prop: 'midLeaveMil',
                                    label: '中间出场运行里程（km）',
                                },
                                {
                                    prop: 'lastBackMil',
                                    label: '末班回场运行里程（km）',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '维修出场运行里程（km）',
                                },
                                {
                                    prop: 'otherLeaveMil',
                                    label: '其他出场运行里程（km）',
                                },
                                {
                                    prop: 'gasFillMil',
                                    label: '加油申请运行里程（km）',
                                },
                                {
                                    prop: 'maintainMil',
                                    label: '维修申请运行里程（km）',
                                },
                                {
                                    prop: 'otherMil',
                                    label: '其他申请运行里程（km）',
                                },
                            ],
                            url:
                                '/statnonoperatedetail/queryNonOperateDataByRoute',
                            type: 3,
                        },
                        {
                            label: '车辆明细',
                            id: 12,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'time',
                                    label: '营运日期',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车辆自编号',
                                },
                                {
                                    prop: 'firstLeaveMil',
                                    label: '首班出场运行里程（km）',
                                },
                                {
                                    prop: 'midBackMil',
                                    label: '中间回场运行里程（km)',
                                },
                                {
                                    prop: 'midLeaveMil',
                                    label: '中间出场运行里程（km）',
                                },
                                {
                                    prop: 'lastBackMil',
                                    label: '末班回场运行里程（km）',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '维修出场运行里程（km）',
                                },
                                {
                                    prop: 'otherLeaveMil',
                                    label: '其他出场运行里程（km）',
                                },
                                {
                                    prop: 'gasFillMil',
                                    label: '加油申请运行里程（km）',
                                },
                                {
                                    prop: 'maintainMil',
                                    label: '维修申请运行里程（km）',
                                },
                                {
                                    prop: 'otherMil',
                                    label: '其他申请运行里程（km）',
                                },
                            ],
                            url:
                                '/statnonoperatedetail/queryNonOperateDetailByVehicle',
                            type: 1,
                        },
                        {
                            label: '司机明细',
                            id: 13,
                            value: [
                                {
                                    prop: 'time',
                                    label: '营运日期',
                                },
                                {
                                    prop: 'driverName',
                                    label: '司机',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'jobNum',
                                    label: '员工工号',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车辆自编号',
                                },
                                {
                                    prop: 'firstLeaveMil',
                                    label: '首班出场运行里程（km）',
                                },
                                {
                                    prop: 'midBackMil',
                                    label: '中间回场运行里程（km)',
                                },
                                {
                                    prop: 'midLeaveMil',
                                    label: '中间出场运行里程（km）',
                                },
                                {
                                    prop: 'lastBackMil',
                                    label: '末班回场运行里程（km）',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '维修出场运行里程（km）',
                                },
                                {
                                    prop: 'otherLeaveMil',
                                    label: '其他出场运行里程（km）',
                                },
                                {
                                    prop: 'gasFillMil',
                                    label: '加油申请运行里程（km）',
                                },
                                {
                                    prop: 'maintainMil',
                                    label: '维修申请运行里程（km）',
                                },
                                {
                                    prop: 'otherMil',
                                    label: '其他申请运行里程（km）',
                                },
                            ],
                            url:
                                '/statnonoperatedetail/queryNonOperateDetailByDriver',
                            type: 2,
                        },
                        {
                            label: '线路明细',
                            id: 14,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'time',
                                    label: '营运日期',
                                },
                                {
                                    prop: 'firstLeaveMil',
                                    label: '首班出场运行里程（km）',
                                },
                                {
                                    prop: 'midBackMil',
                                    label: '中间回场运行里程（km)',
                                },
                                {
                                    prop: 'midLeaveMil',
                                    label: '中间出场运行里程（km）',
                                },
                                {
                                    prop: 'lastBackMil',
                                    label: '末班回场运行里程（km）',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '维修出场运行里程（km）',
                                },
                                {
                                    prop: 'otherLeaveMil',
                                    label: '其他出场运行里程（km）',
                                },
                                {
                                    prop: 'gasFillMil',
                                    label: '加油申请运行里程（km）',
                                },
                                {
                                    prop: 'maintainMil',
                                    label: '维修申请运行里程（km）',
                                },
                                {
                                    prop: 'otherMil',
                                    label: '其他申请运行里程（km）',
                                },
                            ],
                            url:
                                '/statnonoperatedetail/queryNonOperateDetailByRoute',
                            type: 3,
                        },
                    ],
                },
                {
                    label: '砍班统计',
                    id: 15,
                    children: [
                        {
                            label: '车辆汇总',
                            id: 16,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShift',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'cutShift',
                                    label: '砍班班次',
                                },
                                {
                                    prop: 'breakdown',
                                    label: '车辆故障',
                                },
                                {
                                    prop: 'sickLeave',
                                    label: '病假',
                                },
                                {
                                    prop: 'overhaul',
                                    label: '大修',
                                },
                                {
                                    prop: 'custom',
                                    label: '定制公交',
                                },
                                {
                                    prop: 'badWeather',
                                    label: '恶劣天气',
                                },
                                {
                                    prop: 'secondInsurance',
                                    label: '二保',
                                },
                                {
                                    prop: ' legalHoliday',
                                    label: '法定假',
                                },
                                {
                                    prop: 'inductrialInjury',
                                    label: '工伤',
                                },
                                {
                                    prop: 'tolerance',
                                    label: '公差',
                                },
                                {
                                    prop: 'certYearsAudit',
                                    label: '驾驶证年审',
                                },
                                {
                                    prop: ' blocking',
                                    label: '路堵',
                                },
                                {
                                    prop: 'lastShift',
                                    label: '末班砍班',
                                },
                                {
                                    prop: 'other',
                                    label: '其他',
                                },
                                {
                                    prop: ' lackDriving',
                                    label: '缺驾',
                                },
                                {
                                    prop: 'funeralLeave',
                                    label: '丧假',
                                },
                                {
                                    prop: 'accident',
                                    label: '事故',
                                },
                                {
                                    prop: 'personalLeave',
                                    label: '事假',
                                },
                                {
                                    prop: 'firstShift',
                                    label: '首班砍班',
                                },
                                {
                                    prop: 'midShift',
                                    label: '中途砍班',
                                },
                                {
                                    prop: 'weekend',
                                    label: '周末砍班',
                                },
                            ],
                            url:
                                '/statcutshiftdetail/queryCutTotalDetailByVehicle',
                            type: 1,
                        },
                        {
                            label: '司机汇总',
                            id: 17,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'driverName',
                                    label: '司机',
                                },
                                {
                                    prop: 'jobNum',
                                    label: '员工工号',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShift',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'cutShift',
                                    label: '砍班班次',
                                },
                                {
                                    prop: 'breakdown',
                                    label: '车辆故障',
                                },
                                {
                                    prop: 'sickLeave',
                                    label: '病假',
                                },
                                {
                                    prop: 'overhaul',
                                    label: '大修',
                                },
                                {
                                    prop: 'custom',
                                    label: '定制公交',
                                },
                                {
                                    prop: 'badWeather',
                                    label: '恶劣天气',
                                },
                                {
                                    prop: 'secondInsurance',
                                    label: '二保',
                                },
                                {
                                    prop: 'legalHoliday',
                                    label: '法定假',
                                },
                                {
                                    prop: 'inductrialInjury',
                                    label: '工伤',
                                },
                                {
                                    prop: 'tolerance',
                                    label: '公差',
                                },
                                {
                                    prop: 'certYearsAudit',
                                    label: '驾驶证年审',
                                },
                                {
                                    prop: 'blocking',
                                    label: '路堵',
                                },
                                {
                                    prop: 'lastShift',
                                    label: '末班砍班',
                                },
                                {
                                    prop: 'other',
                                    label: '其他',
                                },
                                {
                                    prop: 'lackDriving',
                                    label: '缺驾',
                                },
                                {
                                    prop: 'funeralLeave',
                                    label: '丧假',
                                },
                                {
                                    prop: 'accident',
                                    label: '事故',
                                },
                                {
                                    prop: 'personalLeave',
                                    label: '事假',
                                },
                                {
                                    prop: 'firstShift',
                                    label: '首班砍班',
                                },
                                {
                                    prop: 'midShift',
                                    label: '中途砍班',
                                },
                                {
                                    prop: 'weekend',
                                    label: '周末砍班',
                                },
                            ],
                            url:
                                '/statcutshiftdetail/queryCutTotalDetailByDriver',
                            type: 2,
                        },
                        {
                            label: '线路汇总',
                            id: 18,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShift',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'cutShift',
                                    label: '砍班班次',
                                },
                                {
                                    prop: 'breakdown',
                                    label: '车辆故障',
                                },
                                {
                                    prop: 'sickLeave',
                                    label: '病假',
                                },
                                {
                                    prop: 'overhaul',
                                    label: '大修',
                                },
                                {
                                    prop: 'custom',
                                    label: '定制公交',
                                },
                                {
                                    prop: 'badWeather',
                                    label: '恶劣天气',
                                },
                                {
                                    prop: 'secondInsurance',
                                    label: '二保',
                                },
                                {
                                    prop: 'legalHoliday',
                                    label: '法定假',
                                },
                                {
                                    prop: 'inductrialInjury',
                                    label: '工伤',
                                },
                                {
                                    prop: 'tolerance',
                                    label: '公差',
                                },
                                {
                                    prop: 'certYearsAudit',
                                    label: '驾驶证年审',
                                },
                                {
                                    prop: 'blocking',
                                    label: '路堵',
                                },
                                {
                                    prop: 'lastShift',
                                    label: '末班砍班',
                                },
                                {
                                    prop: 'other',
                                    label: '其他',
                                },
                                {
                                    prop: 'lackDriving',
                                    label: '缺驾',
                                },
                                {
                                    prop: 'funeralLeave',
                                    label: '丧假',
                                },
                                {
                                    prop: 'accident',
                                    label: '事故',
                                },
                                {
                                    prop: 'personalLeave',
                                    label: '事假',
                                },
                                {
                                    prop: 'firstShift',
                                    label: '首班砍班',
                                },
                                {
                                    prop: 'midShift',
                                    label: '中途砍班',
                                },
                                {
                                    prop: 'weekend',
                                    label: '周末砍班',
                                },
                            ],
                            url:
                                '/statcutshiftdetail/queryCutTotalDetailByRoute',
                            type: 3,
                        },
                    ],
                },
                {
                    label: '脱班统计',
                    id: 19,
                    children: [
                        {
                            label: '车辆汇总',
                            id: 20,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShift',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'outShift',
                                    label: '脱班班次',
                                },
                                {
                                    prop: 'sickLeave',
                                    label: '病假',
                                },
                                {
                                    prop: 'late',
                                    label: '迟到',
                                },
                                {
                                    prop: 'absent',
                                    label: '旷工',
                                },
                                {
                                    prop: 'blocking',
                                    label: '路堵',
                                },
                                {
                                    prop: 'other',
                                    label: '其他',
                                },
                                {
                                    prop: 'accident',
                                    label: '事故',
                                },
                                {
                                    prop: 'detainVehicle',
                                    label: '事故扣车',
                                },
                                {
                                    prop: 'deduction',
                                    label: '事故扣证',
                                },
                                {
                                    prop: 'accidentRepair',
                                    label: '事故修复',
                                },
                                {
                                    prop: 'personalLeave',
                                    label: '事假',
                                },
                                {
                                    prop: 'suspended',
                                    label: '停职',
                                },
                                {
                                    prop: 'noReason',
                                    label: '无故脱班',
                                },
                                {
                                    prop: 'minorRepair',
                                    label: '小修',
                                },
                                {
                                    prop: 'bartender',
                                    label: '一保',
                                },
                                {
                                    prop: 'midwayBack',
                                    label: '中途回场',
                                },
                                {
                                    prop: 'midwayLeave',
                                    label: '中途请假',
                                },
                            ],
                            url:
                                '/statoutshiftdetail/queryOutTotalDetailByVehicle',
                            type: 1,
                        },
                        {
                            label: '司机汇总',
                            id: 21,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'driverName',
                                    label: '司机',
                                },
                                {
                                    prop: 'jobNum',
                                    label: '员工工号',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShift',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'outShift',
                                    label: '脱班班次',
                                },
                                {
                                    prop: 'sickLeave',
                                    label: '病假',
                                },
                                {
                                    prop: 'late',
                                    label: '迟到',
                                },
                                {
                                    prop: 'absent',
                                    label: '旷工',
                                },
                                {
                                    prop: 'blocking',
                                    label: '路堵',
                                },
                                {
                                    prop: 'other',
                                    label: '其他',
                                },
                                {
                                    prop: 'accident',
                                    label: '事故',
                                },
                                {
                                    prop: 'detainVehicle',
                                    label: '事故扣车',
                                },
                                {
                                    prop: 'deduction',
                                    label: '事故扣证',
                                },
                                {
                                    prop: 'accidentRepair',
                                    label: '事故修复',
                                },
                                {
                                    prop: 'personalLeave',
                                    label: '事假',
                                },
                                {
                                    prop: 'suspended',
                                    label: '停职',
                                },
                                {
                                    prop: 'noReason',
                                    label: '无故脱班',
                                },
                                {
                                    prop: 'minorRepair',
                                    label: '小修',
                                },
                                {
                                    prop: 'bartender',
                                    label: '一保',
                                },
                                {
                                    prop: 'midwayBack',
                                    label: '中途回场',
                                },
                                {
                                    prop: 'midwayLeave',
                                    label: '中途请假',
                                },
                            ],
                            url:
                                '/statoutshiftdetail/queryOutTotalDetailByDriver',
                            type: 2,
                        },
                        {
                            label: '线路汇总',
                            id: 22,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShift',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'outShift',
                                    label: '脱班班次',
                                },
                                {
                                    prop: 'sickLeave',
                                    label: '病假',
                                },
                                {
                                    prop: 'late',
                                    label: '迟到',
                                },
                                {
                                    prop: 'absent',
                                    label: '旷工',
                                },
                                {
                                    prop: 'blocking',
                                    label: '路堵',
                                },
                                {
                                    prop: 'other',
                                    label: '其他',
                                },
                                {
                                    prop: 'accident',
                                    label: '事故',
                                },
                                {
                                    prop: 'detainVehicle',
                                    label: '事故扣车',
                                },
                                {
                                    prop: 'deduction',
                                    label: '事故扣证',
                                },
                                {
                                    prop: 'accidentRepair',
                                    label: '事故修复',
                                },
                                {
                                    prop: 'personalLeave',
                                    label: '事假',
                                },
                                {
                                    prop: 'suspended',
                                    label: '停职',
                                },
                                {
                                    prop: 'noReason',
                                    label: '无故脱班',
                                },
                                {
                                    prop: 'minorRepair',
                                    label: '小修',
                                },
                                {
                                    prop: 'bartender',
                                    label: '一保',
                                },
                                {
                                    prop: 'midwayBack',
                                    label: '中途回场',
                                },
                                {
                                    prop: 'midwayLeave',
                                    label: '中途请假',
                                },
                            ],
                            url:
                                '/statoutshiftdetail/queryOutTotalDetailByRoute',
                            type: 3,
                        },
                    ],
                },
                {
                    label: '首末班执行统计',
                    id: 23,
                    children: [
                        {
                            label: '车辆汇总',
                            id: 24,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'time',
                                    label: '统计月份',
                                },
                                {
                                    prop: 'planFlShift',
                                    label: '计划首末班次数',
                                },
                                {
                                    prop: 'actualFlShift',
                                    label: '实际首末班次数',
                                },
                                {
                                    prop: 'actualRate',
                                    label: '首末班执行率（%）',
                                },
                                {
                                    prop: 'onTimeNum',
                                    label: '准点班次（%）',
                                },
                                {
                                    prop: 'onTimeRate',
                                    label: '准点率（%）',
                                },
                            ],
                            url:
                                '/statfistlastshiftdaily/queryFistLastShiftByVehicle',
                            type: 1,
                        },
                        {
                            label: '司机汇总',
                            id: 25,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'driverName',
                                    label: '司机',
                                },
                                {
                                    prop: 'jobNum',
                                    label: '员工工号',
                                },
                                {
                                    prop: 'time',
                                    label: '统计月份',
                                },
                                {
                                    prop: 'planFlShift',
                                    label: '计划首末班次数',
                                },
                                {
                                    prop: 'actualFlShift',
                                    label: '实际首末班次数',
                                },
                                {
                                    prop: 'actualRate',
                                    label: '首末班执行率（%）',
                                },
                                {
                                    prop: 'onTimeNum',
                                    label: '准点班次（%）',
                                },
                                {
                                    prop: 'onTimeRate',
                                    label: '准点率（%）',
                                },
                            ],
                            url:
                                '/statfistlastshiftdaily/queryFistLastShiftByDriver',
                            type: 2,
                        },
                    ],
                },
                {
                    label: '工作时长统计',
                    id: 26,
                    children: [
                        {
                            label: '司机汇总',
                            id: 27,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'driverName',
                                    label: '司机',
                                },
                                {
                                    prop: 'jobNum',
                                    label: '员工工号',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车辆自编号',
                                },
                                {
                                    prop: 'workHours',
                                    label: '实际工作时长',
                                },
                            ],
                            url: '/statworkhours/queryDriverWorkTimeTotal',
                            type: 2,
                        },
                        {
                            label: '司机明细',
                            id: 28,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'time',
                                    label: '营运日期',
                                },
                                {
                                    prop: 'driverName',
                                    label: '司机',
                                },
                                {
                                    prop: 'jobNum',
                                    label: '员工工号',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车辆自编号',
                                },
                                {
                                    prop: 'signInTime',
                                    label: '实际签到时间',
                                },
                                {
                                    prop: 'signOutTime',
                                    label: '实际签退时间',
                                },
                                {
                                    prop: 'workHours',
                                    label: '实际工作时长',
                                },
                            ],
                            url: '/statworkhours/queryDriverWorkTimeDetail',
                            type: 2,
                        },
                    ],
                },
                {
                    label: '定点专线统计',
                    id: 29,
                    children: [
                        {
                            label: '车辆汇总',
                            id: 30,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车辆自编号',
                                },
                                {
                                    prop: 'totalMileage',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'runMileage',
                                    label: '运行里程（km）',
                                },
                                {
                                    prop: 'shiftConversion',
                                    label: '班次折算',
                                },
                            ],
                            url:
                                '/statrentdaily/queryRentOperationDataByVehicle',
                            type: 1,
                        },
                        {
                            label: '司机汇总',
                            id: 31,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'driverName',
                                    label: '司机',
                                },
                                {
                                    prop: 'jobNum',
                                    label: '员工工号',
                                },
                                {
                                    prop: 'totalMileage',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'runMileage',
                                    label: '运行里程（km）',
                                },
                                {
                                    prop: 'shiftConversion',
                                    label: '班次折算',
                                },
                            ],
                            url:
                                '/statrentdaily/queryRentOperationDataByDriver',
                            type: 2,
                        },
                        {
                            label: '车辆明细',
                            id: 32,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'time',
                                    label: '营运日期',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'totalMileage',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'runMileage',
                                    label: '运行里程（km）',
                                },
                                {
                                    prop: 'shiftConversion',
                                    label: '班次折算',
                                },
                            ],
                            url:
                                '/statrentdaily/queryRentOperationDetailByVehicle',
                            type: 1,
                        },
                        {
                            label: '司机明细',
                            id: 33,
                            value: [
                                {
                                    prop: 'time',
                                    label: '营运日期',
                                },
                                {
                                    prop: 'driverName',
                                    label: '司机',
                                },
                                {
                                    prop: 'jobNum',
                                    label: '员工工号',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'totalMileage',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'runMileage',
                                    label: '运行里程（km）',
                                },
                                {
                                    prop: 'shiftConversion',
                                    label: '班次折算',
                                },
                            ],
                            url:
                                '/statrentdaily/queryRentOperationDetailByDriver',
                            type: 2,
                        },
                    ],
                },
                {
                    label: '运营里程统计',
                    id: 34,
                    children: [
                        {
                            label: '车辆汇总',
                            id: 35,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车辆自编号',
                                },
                                {
                                    prop: 'operatingMil',
                                    label: '营运里程（km）',
                                },
                                {
                                    prop: 'nonOperatingMil',
                                    label: '非营运里程（km）',
                                },
                                {
                                    prop: 'rentShiftMil',
                                    label: '定点专线里程（km）',
                                },
                                {
                                    prop: 'totalMil',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'runTotalMil',
                                    label: '运行里程（km）',
                                },
                            ],
                            url: '/statoperatingdaily/queryVehiclePageList',
                            type: 1,
                        },
                        {
                            label: '车辆明细',
                            id: 36,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'time',
                                    label: '营运日期',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'vehicleId',
                                    label: '车辆自编号',
                                },
                                {
                                    prop: 'operatingMil',
                                    label: '营运里程（km）',
                                },
                                {
                                    prop: 'nonOperatingMil',
                                    label: '非营运里程（km）',
                                },
                                {
                                    prop: 'rentShiftMil',
                                    label: '定点专线里程（km）',
                                },
                                {
                                    prop: 'totalMil',
                                    label: '总里程（km）',
                                },
                                {
                                    prop: 'runTotalMil',
                                    label: '运行里程（km）',
                                },
                            ],
                            url:
                                '/statoperatingdaily/queryOperateDetailByVehicle',
                            type: 1,
                        },
                    ],
                },
                {
                    label: '日营运报表',
                    id: 37,
                    children: [
                        {
                            label: '线路汇总',
                            id: 38,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'planShift',
                                    label: '计划班次',
                                },
                                {
                                    prop: 'actualShfit',
                                    label: '实际班次',
                                },
                                {
                                    prop: 'depOnTimeRate',
                                    label: '发车准点率（%）',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '班次完成率（%）',
                                },
                                {
                                    prop: 'cutShift',
                                    label: '砍班班次',
                                },
                                {
                                    prop: 'outShift',
                                    label: '脱班班次',
                                },
                                {
                                    prop: 'rentShift',
                                    label: '定点专线班次',
                                },
                            ],
                            url: '/statoperatingdaily/queryPageListByRoute',
                            type: 3,
                        },
                    ],
                },
                {
                    label: '车辆过站明细',
                    id: 39,
                    children: [
                        {
                            label: '车辆明细',
                            id: 40,
                            value: [
                                {
                                    prop: 'routeName',
                                    label: '所属线路',
                                },
                                {
                                    prop: 'orgName',
                                    label: '所属机构',
                                },
                                {
                                    prop: 'operationDate',
                                    label: '营运日期',
                                },
                                {
                                    prop: 'vehicleNo',
                                    label: '车牌号',
                                },
                                {
                                    prop: 'deviceId',
                                    label: '设备ID',
                                },
                                {
                                    prop: 'routeType',
                                    label: '线路类型',
                                },
                                {
                                    prop: 'des',
                                    label: '运行方向',
                                },
                                {
                                    prop: 'siteTypeName',
                                    label: '站点类别',
                                },
                                {
                                    prop: 'siteName',
                                    label: '站点名称',
                                },
                                {
                                    prop: 'loginUser',
                                    label: '顺序号',
                                },
                                {
                                    prop: 'inTime',
                                    label: '进站时间',
                                },
                                {
                                    prop: 'getOnCount',
                                    label: '上车人数',
                                },
                                {
                                    prop: 'getOffCount',
                                    label: '下车人数',
                                },
                                {
                                    prop: 'outTime',
                                    label: '出站时间',
                                },
                                {
                                    prop: 'status',
                                    label: '状态',
                                },
                            ],
                            url: '/statvehiclestopdetails/queryPageList',
                            type: 1,
                        },
                    ],
                },
            ],
            defaultProps: {
                children: 'children',
                label: 'label',
            },
            // 组件传值
            labelProp: [
                {
                    prop: 'vehicleNo',
                    label: '车牌号',
                },
                {
                    prop: 'routeName',
                    label: '所属线路',
                },
                {
                    prop: 'orgName',
                    label: '所属机构',
                },
                {
                    prop: 'customNo',
                    label: '车辆自编号',
                },
                {
                    prop: 'planShift',
                    label: '计划班次',
                },
                {
                    prop: 'actualShfit',
                    label: '实际班次',
                },
                {
                    prop: 'completeShift',
                    label: '完成班次',
                },
                {
                    prop: 'incompleteShift',
                    label: '未完成班次',
                },
                {
                    prop: 'rentShift',
                    label: '定点专线班次',
                },
                {
                    prop: 'operatingMil',
                    label: '营运里程（km）',
                },
                {
                    prop: 'rentShiftMil',
                    label: '定点专线里程（km）',
                },
                {
                    prop: 'nonOperatingMil',
                    label: '非营运里程（km）',
                },
                {
                    prop: 'totalMil',
                    label: '总里程（km）',
                },
                {
                    prop: 'runTotalMil',
                    label: '运行总里程（km）',
                },
                {
                    prop: 'planShift',
                    label: '应开班次',
                },
                {
                    prop: 'cutShift',
                    label: '砍班班次',
                },
                {
                    prop: 'cutShiftRate',
                    label: '砍班率（%）',
                },
                {
                    prop: 'outShift',
                    label: '脱班班次',
                },
                {
                    prop: 'outShiftRate',
                    label: '脱班率（%）',
                },
                {
                    prop: 'shiftExecutionRate',
                    label: '班次执行率（%）',
                },
                {
                    prop: 'depOnTimeNum',
                    label: '准点发班数',
                },
                {
                    prop: 'depOnTimeRate',
                    label: '发车准点率（%）',
                },
            ],
        }
    },
    created() {
        this.$nextTick(() => {
            this.$refs.listData.getList()
        })
    },
    watch: {
        type: {
            immediate: true,
            handler(val) {
                if (val == 1) {
                    this.driverIs = true
                    this.carIs = false
                    this.formInline.driverName = ''
                } else if (val == 2) {
                    this.carIs = true
                    this.driverIs = false
                    this.formInline.vehicleNo = ''
                } else {
                    this.driverIs = true
                    this.carIs = true
                    this.formInline.vehicleNo = ''
                    this.formInline.driverName = ''
                }
            },
        },
        'formInline.wztime'(val) {
            console.log(val)
            if (val.length != 1) {
                this.formInline.beginTime = val[0]
                this.formInline.endTime = val[1]
            } else {
                this.formInline.beginTime = ''
                this.formInline.endTime = ''
            }
        },
    },
    components: {
        list,
        institutionsTree,
    },
    methods: {
        handleNodeClick(data) {
            if (data.value) {
                this.labelProp = data.value
                this.url = data.url
                this.type = data.type
                console.log(this.url)
                this.$nextTick(() => {
                    this.$refs.listData.getList()
                })
            }
        },
        // 查询表格
        oncheck() {
            console.log(this.$refs.listData)
            this.$refs.listData.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        // 导出
        onexport() {
            console.log('导出')
        },

        // 选择机构树
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        // 上级机构查询
        addInstitutions(data) {
            // console.log(data)
            this.formInline.orgId = data.orgId
            this.formInline.orgName = data.name
        },
        // 分页
        // handleSizeChange(val) {
        //   console.log(`每页 ${val} 条`)
        //   this.pageSize = val
        //   this.getList()
        // },
        // handleCurrentChange(val) {
        //   console.log(`当前页: ${val}`)
        //   this.pageIndex = val
        //   this.getList()
        // }
    },
}
</script>
<style lang="scss" scoped="reportCenter">
.reportCenter {
    .container_box {
        display: flex;

        .left_box {
            width: 200px;

            .text_box {
                font-size: 18px;
                margin-bottom: 10px;
            }

            .tree_box {
                margin-left: 20px;
            }
        }

        .right_box {
            flex: 1;
            overflow-x: hidden;
        }
    }
}
</style>
